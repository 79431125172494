<template>
  <v-data-table :headers="headers" :items="listPph" dense @click:row="onClickRow" :search="search">
    <template v-slot:top>
      <v-toolbar flat>
        <v-row class="mt-2">
          <v-col cols="12" sm="12" md="8" lg="8">
            <v-text-field
              flat
              dense
              outlined
              background-color="white"
              v-model="search"
              placeholder="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" class="text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" dark rounded :to="{ name: 'Create PPH 23 Code' }">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>Create</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ listPph.map(x => x).indexOf(item) + 1 }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "pph-23-code",
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Code",
        value: "code",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Used",
        value: "used",
        sortable: false,
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    search: "",
  }),

  computed: {
    ...mapState("pph", ["listPph"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("pph/getPph23Code")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail PPH 23 Code", params: { id: item.id } });
    },
  },
};
</script>

<style></style>
